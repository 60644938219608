.form-manual-size{
    margin: 5px 0;
    width: 65vw;
}

.col{
    margin-top: 5px;
}

.formButton{
    background-color: #4dad15;
}

.formButton:hover{
    background-color: #6c8016;

}
